body
    background: black
    overscroll-behavior: none
    main
        background: white
        width: 300px
        min-height: 100vh
        float: right
        .content
            padding: 40px
        .photo
            width: 100px
            height: auto
            border-radius: 100%
    &.--blog
        main
            width: 600px
            float: none
            left: 50%
            position: absolute
            margin-left: -300px
            margin-top: 30vh
            margin-bottom: 30vh
        img
            max-width: 100%
            margin-bottom: 30px
        h1
            margin-top: 0

h1, p, li
    font-family: 'EB Garamond', serif

a
    color: black

h1
    font-size: 36px
    margin-top: 40px
    margin-bottom: 20px
    letter-spacing: -0.2px
    text-rendering: geometricPrecision

p
    a
        text-decoration-thickness: 1px
        text-decoration-color: #CCCCCC
        text-underline-offset: 3px
        transition: text-decoration .5s
        &:hover
            text-decoration-color: #000000

h2
    font-family: 'EB Garamond', serif
    font-size: 24px
    font-style: italic
    line-height: 28px
    margin-bottom: 20px
    letter-spacing: -0.2px
    text-rendering: geometricPrecision
    a
        text-decoration: none

h3
    font-family: 'Montserrat', sans-serif
    font-weight: 700
    font-size: 12px
    line-height: 22px
    a
        text-decoration: none
p
    font-family: 'Montserrat', sans-serif
    font-size: 12px
    line-height: 22px
    margin-bottom: 20px

ul
    list-style: none
    li
        line-height: 28px
        a
            font-family: 'Montserrat', sans-serif
            font-size: 9px
            font-weight: 700
            letter-spacing: 1px
            color: black
            text-transform: uppercase
            text-decoration: none
            transition: all 0.5s
            white-space: nowrap
            &:hover
                color: grey

.legal
    font-size: 12px
    margin-top: 40px

@media screen and (max-width: 640px)
    body, body.--blog
        main
            position: relative
            float: none
            margin: 70vh 0 0 0
            width: 100%
            left: inherit
            .content
                position: relative
            .photo
                top: -50px
                position: absolute
    ul
      li
        display: inline-block
        a
          display: block
          padding: 15px 30px 15px 0
          min-width: 100px
