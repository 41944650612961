#background
    z-index: -100
    user-select: none
    position: fixed
    top: -50vh
    left: -20vh
    right: -20vh
    bottom: -100vh
#feed
    width: 100%
    height: 100%
    align-items: flex-start
    align-content: flex-start
    display: flex
    flex-direction: column
    flex-wrap: wrap
    transform-origin: center
    transform: rotate(-25deg)
    img
        transition: opacity 0.5s ease-in-out
        margin: 0
        width: 200px

@media screen and (max-width: 640px)
    #feed img
        width: 140px

@media screen and (min-width: 1280px)
    #feed img
        width: 260px
